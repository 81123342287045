<template>
  <div
    v-if="requisitionPickUpText && !isDemoUser"
    class="RequisitionPickUpNotice"
    v-html="requisitionPickUpText"/>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'RequisitionPickUpNotice',
  computed: {
    ...mapGetters([
      'isDemoUser',
      'requisitionPickUpText',
    ]),
  },
}
</script>

<style lang="stylus" scoped>
  .RequisitionPickUpNotice
    padding 10px
    background-color #fff
    box-shadow $box_shadow_1
    font-weight bold
    a
      font-weight inherit
      text-decoration underline
</style>

<style lang="stylus">
  .RequisitionPickUpNotice
    padding 10px
    background-color #fff
    box-shadow $box_shadow_1
    font-weight bold
    a
      font-weight inherit
      text-decoration underline
</style>
